#team-rankings-box {
  background-color: white;
  width: 90vw;
  margin: 20px auto;
  border: 7px ridge goldenrod;
  max-height: 900px;
  overflow: scroll;
}

.oneTeamTR {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  padding: 0 10px;
  border-bottom: 1px solid black;
}

#TRHeaderColumn { 
    top: 0;
    left: 0;
    position: sticky;
    background-color: #fff;
}

.oneTeamTR:nth-child(2n - 1) {
  background-color: rgba(5, 5, 5, 0.1);
}

.oneTeamTR h2 {
  font-size: 16px;
  width: 180px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.oneTeamTR h2::-webkit-scrollbar {
  display: none;
}

.oneTeamTR p {
  width: 70px;
  margin: 5px auto;
  font-size: 14px;
}

.totalTR {
  width: 70px;
}

#TRHeader, #TRAverage, #TRWeighted, #TRTotal {
    font-size: 14px;
}

#about-box {
  background-color: antiquewhite;
  border-radius: 20px;
  padding: 10px;
  width: 90vw;
  max-width: 800px;
  margin: 10px auto;
  border: 1px solid black;
}

@media screen and (max-width: 600px) {

  #team-rankings-box {
    border: 3px solid goldenrod;
    font-size: 10px;
  }

  .oneTeamTR {
    margin: 0;
    width: fit-content;
    gap: 5px;
  }

  .oneTeamTR h2 {
    width: 70px;
    margin: 10px 0;
  }

  .totalTR {
    width: 40px;
  }

  .oneTeamTR p {
    width: 30px;
    margin: 5px auto;
  }

   .oneTeamTR h3, .oneTeamTR h2 {
    font-size: 10px;
  }
  
  .oneTeamTR p, #TRHeader, #TRAverage, #TRWeighted, #TRTotal, #TRUsername {
    font-size: 8px;
  }  
}
