/* Account page */

#AccountLogin {
    text-align: center;
    margin: 3vw auto 0  auto;
    width: 80%;
    max-width: 470px;
    padding: 20px;
    border: 1px solid black;
    border-radius: 20px;
    background-color: white;
  }
  
  #AccountLoginForm {
    display: block;
    width: 1000px;
    margin: 0 auto;
    color: black;
  }
  
  #AccountPage {
    text-align: center;
    max-width: 850px;
    width: 80vw;
    padding: 20px;
    margin: 20px auto;
    background-color: white;
    border: 1px solid black;
    border-radius: 10px;
  }
  
  #AccountHello, #NoTeam {
    margin: 0px auto;
    width: 60vw;
    max-width: 510px;
  }

  #NoTeam {
    margin-top: 10px;
  }

  #delay {
    width: 75%;
    min-width: 300px;
    margin: auto;
  }
  
  #AccountTeam {
    border: 5px ridge goldenrod;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
    gap: 2vw;
    width: 90%;
    margin: 0px auto;
    padding: 10px;
    background-color: rgb(229, 202, 133);
  }
  
  .AccountOneRikishi {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    max-width: 300px;
    justify-content: center;
    margin: 10px auto;
    background-color: white;
    padding: 5px 20px;
    border: 1px solid black;
    border-radius: 50px;
  }
  
  .AORrank {
    width: 70px;
  }
  
  .AORshikona {
    width: 150px;
  }
  
  .AORscore {
    width: 30px;
  }
  
  #ATTotal {
    background-color: white;
    border: 10px ridge rgb(184, 2, 2);
    padding: 10px 40px;
  }
  
  #ATTotal h1 {
    font-size: 60px;
    margin: 0px;
  }
  
  #ATTotal h2 {
    font-size: 40px;
    margin: 0px;
  }
  
  #Redraft {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    gap: 40px;
    align-items: center;
    background-color: rgba(5,5,5,.1);
    border: 2px solid rgb(184, 2, 2);
    border-radius: 10px;
  }
  
  #Redraft h4 {
    width: 400px;
  }
  
  #Redraft button {
    height: 50px;
    font-size: 20px;
    padding: 10px;
    color: white;
    background-color: rgb(184, 2, 2);
    border: 2px solid black;
  }

  #fullOneTeam {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    gap: 20px 50px;
    background-color: rgb(229, 202, 133);
    width: 400px;
    padding: 20px;
    margin: 2vh auto;
    border: 5px ridge goldenrod;
  }

  #oneTeam {
    background-color: white;
    border: 1px solid black;
    border-radius: 10px;
  }

  #oneTeam div {
    display: grid;
    grid-template-columns: 75% 22%;
    gap: 3%;
    width: 200px;
  }

  #oneTeam div p {
    margin: 5px 10px;
  }

  #oneTeamRank {
    background-color: white;
    padding: 10px;
    border: 5px ridge rgb(184, 2, 2);
  }

  #oneTeamRank h2 {
    margin: 5px;
  }

  @media screen and (max-width: 600px) {
    #AccountLogin {
      text-align: center;
      margin: 50px auto;
      width: fit-content;
      padding: 10px 20px;
      margin: 3vw auto;
      font-size: 20px;
      border: 1px solid black;
      background-color: white;
    }
  
    #AccountPage {
      width: 90%;
      margin: 10px auto;
      padding: 10px;
    }
  
    #AccountPage h3 {
      font-size: 14px;
    }
  
    #AccountTeam {
      width: 90%;
      margin: 0px auto;
      padding: 5px;
    }
  
    .AccountOneRikishi {
      width: 80%;
      margin: 3px auto;
      background-color: white;
      padding: 0px 20px;
      border: 1px solid black;
      border-radius: 50px;
    }
  
    .AccountOneRikishi img {
      height: 40px;
    }
  
    .AORrank {
      width: 50px;
    }
  
    .AORshikona {
      width: 150px;
    }
  
    .AORscore {
      width: 30px;
    }

    #ATTotal h1, #ATTotal h2 {
      font-size: 24px;
    }

    #ATTotal {
      padding: 10px 20px;
      border: 5px ridge rgb(184, 2, 2);
    }
  
    #Redraft {
      gap: 15px;
    }
  
    #Redraft h4 {
      width: 60%;
      font-size: 12px;
    }
  
    #Redraft button {
      height: 40px;
      font-size: 12px;
    }

    #fullOneTeam {
      flex-flow: row wrap;
      width: 90%;
      padding: 0 0 5px 0;
    }
    
  }