body {
  margin: 0;
  font-family: "Nunito Sans";
}

button {
  background-color: white;
  box-shadow: 0px 1px 3px;
  border-radius: 20px;
  height: 30px;
}

#hello {
  max-width: 550px;
  width: 80vw;
  background-color: white;
  border: 4px ridge goldenrod;
  border-radius: 10px;
  box-shadow: 0px 1px 10px goldenrod;
  margin: 20px auto;
  padding: 5px 10px;
  text-align: center;
}

#BI {
  min-height: 100%;
  min-width: 1024px;
  width: 100%;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: .35;
}

@media screen and (max-width: 600px) {
  body {
    margin: 5px 0 0 0;
  }

  #BI {
    height: 100vh;
  }
}