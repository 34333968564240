/* Rules & Scoring */

#RulesTopFlex {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    margin: 40px 20px;
    gap: 10px;
  }
  
  #RulesText,
  #ScoringFacts {
    width: 800px;
    text-align: center;
    background-color: white;
    border: 1px solid black;
    padding: 10px;
    border-radius: 10px;
  }
  
  #HowItWorks li {
    width: 180px;
    text-align: left;
    margin: 0 auto;
  }
  
  #HWFlex {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: center;
    margin: -20px 10px;
  }

  #healthy {
    max-width: 360px;
  }
  
  #ScoringFlex {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: center;
  }
  
  #Scoring li {
    list-style-position: outside;
    width: 100%;
    margin: 0 auto;
    text-align: left;
  }
  
  #Kotomitsuki {
    display: block;
    width: 400px;
    border: 5px ridge gold;
  }
  
  #Terminology {
    margin: 20px auto;
    text-align: center;
    background-color: white;
    width: 400px;
    padding: 5px 20px;
    border: 3px ridge black;
    border-radius: 30px;
  }

  #TerminologyLink, #DBLink {
    display: block;
    padding: 5px 10px;
    box-shadow: 0px 1px 3px;
    border-radius: 30px;
    width: fit-content;
    margin: 10px auto;
    text-decoration: none;
    color: black;
  }

  #DBLink {
    display: inline;
    padding: 2px 10px;
  }
  
  #MoreFacts {
    margin: 50px auto;
    text-align: center;
    background-color: white;
    width: 800px;
    padding: 10px 50px;
    border: 3px ridge black;
    border-radius: 100px;
  }

  @media screen and (max-width: 600px) {

    #RulesTopFlex {
      margin: 20px 10px;
      gap: 10px;
    }
  
    #RulesTopFlex p {
      font-size: calc((1.7 - 1) * 1.2vw + .6rem);
    }
  
    #RulesTopFlex h2,
    h3 {
      font-size: calc((1.7 - 1) * 1.2vw + 1rem);
    }
  
    #RulesText,
    #ScoringFacts {
      width: 90%;
      padding: 0 10px;
    }
  
    #HowItWorks li {
      margin: 0 auto;
      font-size: calc((1.7 - 1) * 1.2vw + .6rem);
    }
  
    #HWFlex {
      flex-flow: row wrap;
      margin: -10px 10px;
    }
  
    #Scoring li {
      font-size: calc((1.7 - 1) * 1.2vw + .6rem);
      width: 90%;
      margin: 0;
    }
  
    #ScoringFacts h3 {
      font-size: 14px;
    }
  
    #ScoringFlex {
      flex-flow: row wrap;
    }

    #Scoring ul {
      padding-inline-start: 20px;
      width: 100%;
    }
  
    #Kotomitsuki {
      display: block;
      width: calc(100% - 20px);
      border: 5px ridge gold;
    }

    #Terminology {
      width: 70%;
      font-size: calc((1.7 - 1) * 1.2vw + .7rem);
      border: 2px solid black;
    }
  
    #MoreFacts {
      margin: 20px auto;
      width: 80%;
      padding: 10px 10px;
      border: 2px ridge black;
      border-radius: 40px;
    }
  
    #MoreFacts p, #Terminology p {
      font-size: calc((1.7 - 1) * 1.2vw + .7rem);
    }
  }