/* for the sign up form */

#SignupFlex {
  margin: 0;
  padding: 0 20px;
  height: fit-content;
  background-color: rgb(250,240,215);
  border-radius: 10px;
  /* border: 1px solid black; */
  box-shadow: 0px 1px 5px rgba(0,0,0,.7);
}

#SignupLines {
  margin: 20px auto;
  /* background-color: white; */
  padding: 1px 20px;
  border-radius: 20px;
}

.SignupLine {
  display: grid;
  grid-template-columns: 100px 220px;
  gap: 40px;
  align-items: center;
  width: 360px;
  margin: 20px auto;
  font-size: 20px;
  height: 30px;
}

.SignupLine label {
  width: 150px;
}

.SignupLine input {
  font-size: 20px;
  height: 30px;
  width: 212px;
  margin: 0;
}

#pc {
  font-size: 14px;
}

#SignupFlex button {
  display: block;
  margin: 10px auto;
  height: 35px;
  width: 120px;
  font-size: 18px;
  border: 0;
  box-shadow: 0px 1px 10px goldenrod;
}

#SignupFlex button:hover, .SignupLine input:hover {
  border: 2px inset rgb(184,2,2);
}

@media screen and (max-width: 600px) {

  #SignupFlex {
    width: 80%;
    padding: 1px 10px;
    height: auto;
  }

  #SignupLines {
    margin: 10px 0;
    padding: 1px 5px 1px 10px;
  }

  .SignupLine {
    width: 100%;
    grid-template-columns: 70px auto;
    gap: 20px;
    margin: 15px auto;
    font-size: 14px;
  }

  .SignupLine label {
    width: 70px;
  }

  .SignupLine input {
    font-size: 16px;
    width: calc(65vw - 70px);
  }

  #SignupFlex button {
    margin: 20px auto 10px auto;
  }
}