/* for the Database */

#DBTop {
  margin: 30px 0px 10px 0px;
}

#DBTitle {
  width: 300px;
  background-color: antiquewhite;
  text-align: center;
  border: 3px solid goldenrod;
  border-radius: 10px;
  margin: auto;
}

#DBTopSpace {
  width: 120px;
}

#DBSubMenu {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 5px 10px;
  margin: 10px auto;
  width: fit-content;
  background-color: antiquewhite;
  border-radius: 50px;
}

#DBSubMenu button {
  font-size: 20px;
  height: 40px;
}

#DBViewState {
  width: 120px;
  border: 2px solid black;
  color: black;
}

#DBViewState:hover {
  border: 2px inset rgb(184, 2, 2);
}

#DBContainer {
  width: 90vw;
  margin: auto;
}

#howToSort {
  background-color: white;
  width: fit-content;
  margin: auto;
  border: 2px solid rgb(184, 2, 2);
  padding: 10px;
  border-radius: 20px;
  text-align: center;
}

#DBTable {
  background-color: white;
  border: 7px ridge goldenrod;
  margin: 20px auto;
  max-width: 1300px;
  height: 800px;
  overflow-x: scroll;
}

#hrFSStats {
  display: inline-block;
  width: 100%;
}

#hrStats {
  display: inline-block;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
}

#DBAllRikishi {
  height: 740px;
}

#DBTableColumns,
.DBOneRikishi {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  height: 60px;
  text-align: center;
  min-width: 750px;
}

#DBTableColumns {
  height: 57px;
  position: sticky;
  top: 0;
  left: 0;
  background-color: white;
  border-bottom: 1px solid black;
}

#DBTableColumns p {
  font-size: 12px;
  margin: 0;
}

.DBOneRikishi:nth-child(2n) {
  background-color: rgba(5, 5, 5, 0.05);
}

.DBImage {
  width: 60px;
}

.DBCurrent,
.DBHighest {
  width: 40px;
  text-align: center;
}

.DBShikona {
  width: 120px;
}

.DBHeya {
  width: 100px;
}

.DBAge,
.DBWeight,
.DBHeight {
  width: 40px;
}

.DBYusho,
.DBKinboshi,
.DBSansho {
  width: 40px;
}

.DBScores {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
}

.DBBasho {
  width: 50px;
  text-align: center;
}

.DBAvg {
  width: 60px;
  font-weight: 800;
}

.DBCol {
  color: black;
}

.DBStatsActive {
  color: red;
}

@media screen and (max-width: 600px) {
  #howToSort {
    width: 80vw;
    margin: auto;
    font-size: calc((2 - 1) * 1.2vw + 0.5rem);
  }

  #hrFSStats {
    display: inline-block;
    width: 670px;
  }

  #hrStats {
    display: inline-block;
    width: 510px;
  }

  #DBTop {
    margin: 10px 10px 0 10px;
  }

  #DBTopSpace,
  #DBViewState {
    width: 20vw;
    font-size: calc((2 - 1) * 1.2vw + 0.3rem);
    height: 40px;
  }

  #DBTitle {
    width: fit-content;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: calc((1.7 - 1) * 1.2vw + 0.8rem);
  }

  #DBContainer {
    width: 100%;
  }

  #DBTable {
    margin: 10px auto;
    width: 95%;
    height: 72vh;
    border: 3px ridge goldenrod;
    text-decoration: none;
  }

  #DBTableColumns,
  .DBOneRikishi {
    justify-content: left;
    height: 35px;
    width: 515px;
    min-width: 300px;
  }

  #DBTableColumns p,
  .DBOneRikishi p {
    font-size: 8px;
    flex-shrink: 0;
  }

  .DBImage {
    width: 35px;
    height: 35px;
  }

  .DBCurrent,
  .DBHighest {
    width: 25px;
    text-align: center;
  }

  .DBShikona {
    width: 60px;
  }

  .DBHeya {
    width: 60px;
  }

  .DBAge,
  .DBWeight,
  .DBHeight {
    width: 35px;
  }

  .DBYusho,
  .DBKinboshi,
  .DBSansho {
    width: 35px;
  }

  .DBBasho {
    text-align: center;
    width: 40px;
  }

  .DBAvg {
    width: 50px;
    font-weight: 800;
  }

  #DBSubMenu button {
    font-size: 12px;
    height: auto;
    padding: 5px;
    color: black;
    text-decoration: none;
    box-shadow: none;
    border: 1px solid black;
  }

  #DBSubMenu {
    gap: 10px;
    padding: 5px;
    margin: 10px auto;
    width: fit-content;
    border-radius: 50px;
  }
}

main {
  width: 100%;
  margin: 20px auto;
}

#dbtest-title {
  background-color: white;
  text-align: center;
  width: 400px;
  margin: 20px auto;
  padding: 10px;
  border: 3px solid black;
  border-radius: 20px;
}

#dbtest-all-filters {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  max-width: 1300px;
  margin: 0 auto;
}

#dbtest-filters {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 20px;
  width: 704px;
}

#dbtest-filters div {
  width: 320px;
  background-color: white;
  border: 1px solid black;
  padding: 10px;
  border-radius: 20px;
}

#retired-checkbox {
  background-color: white;
  padding: 5px 10px;
  border: 1px solid black;
  border-radius: 10px;
}

#retiredCheckbox {
  margin: 0 5px 0 0;
}

@media screen and (max-width: 1000px) {
  #dbtest-all-filters {
    flex-flow: column-reverse nowrap;
  }

  #dbtest-filters {
    width: 90vw;
    gap: 10px;
  }
}

input {
  margin: 0px 30px;
}

table {
  table-layout: fixed;
  display: block;
  width: 100%;
  height: 70vh;
  margin: 20px 0;
  overflow: scroll;
  border-collapse: collapse;
  border: 7px ridge goldenrod;
  background-color: white;
}

thead th {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
}

/* table tbody th */
.dbtest-one-rikishi-header {
  display: flex;
  flex-flow: row nowrap;
  position: sticky;
  left: 0;
  z-index: 1;
  background: white;
}

/* table thead th:first-child */
#dbtest-basho-sticky {
  display: flex;
  flex-flow: row nowrap;
  position: sticky;
  left: 0;
  z-index: 2;
}

#dbtest-basho-row {
  display: flex;
  position: sticky;
  top: 0;
  z-index: 3;
  flex-flow: row nowrap;
  align-items: flex-end;
  background: white;
  height: 50px;
  width: calc(267px + (50px * 401));
  border-bottom: 1px solid black;
}

tbody,
thead,
.dbtest-one-rikishi {
  width: calc(267px + (50px * 401));
}

.dbtest-one-rikishi:nth-of-type(even),
.dbtest-basho:nth-child(2n + 1),
.dbtest-one-score:nth-child(even),
.dbtest-one-rikishi-header:nth-of-type(2n) {
  background: rgba(194, 194, 194, 0.3);
}

.dbtest-one-score {
  height: 14px;
}

.dbtest-one-score,
.dbtest-basho {
  width: 40px;
  margin: 0;
  vertical-align: bottom;
  padding: 18px 5px;
  font-size: 10px;
  flex-shrink: 0;
  text-align: center;
}

.dbtest-one-rikishi-scores {
  display: flex;
  flex-flow: row nowrap;
  justify-content: left;
  align-items: center;
  width: calc(90vw - 246px);
}

.dbtest-one-rikishi {
  display: flex;
  flex-flow: row nowrap;
  justify-content: left;
  align-items: center;
  height: 50px;
  border-bottom: 1px solid black;
}

tbody div th .dbtest-rikishi-image {
  width: 50px;
}

.dbtest-basho-image {
  width: 50px;
  flex-shrink: 0;
  text-align: center;
}

.dbtest-rikishi-name {
  width: 118px;
  flex-shrink: 0;
  margin: 0;
  text-align: center;
  /* height: fit-content; */
}

.dbtest-one-rikishi-header .dbtest-rikishi-name {
  vertical-align: middle;
  height: fit-content;
  margin: auto;
}

.dbtest-rikishi-link-hover {
  width: 120px;
  padding: 10px 0;
  margin: auto;
}

.dbtest-rikishi-link-hover .dbtest-rikishi-name {
  width: 116px;
  padding: 0;
  text-align: center;
}

.dbtest-rikishi-link-hover:hover {
  border: 2px solid rgb(184, 2, 2);
  width: 116px;
}

.dbtest-rikishi-avg,
.dbtest-basho-avg,
.dbtest-basho-total,
.dbtest-rikishi-total {
  width: 70px;
  flex-shrink: 0;
  text-align: center;
  margin: auto;
}

.dbtest-rikishi-avg {
  vertical-align: middle;
}

.dbtest-basho-avg,
.dbtest-basho-image,
.dbtest-basho-total,
#shikona {
  font-size: 10px;
  padding: 18px 1px;
}

.dbtest-one-rikishi-header {
  padding: 0;
}

.dbtest-one-rikishi-header > img,
h4,
p {
  padding: 0px 1px;
}

.more-padding {
  padding-top: 25px;
  padding-bottom: 25px;
}

#rikishi-info-dialog {
  z-index: 5;
  display: block;
  width: 80vw;
  max-width: 600px;
  position: absolute;
  top: 400px;
  right: 0;
  padding: 0 1em;
}

#rikishi-info-close {
  position: absolute;
    right: 10px;
    top: 10px;
    width: 40px;
    height: 40px;
    padding: 0;
    background-color: red;
    color: #fff;
    border: 1px solid black;
    border-radius: 2px;
    font-size: 18px;
}

.rikishi-info-title {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.rikishi-info-title img {
  height: 60px;
  width: 60px;
  margin: 20px;
}

#rikishi-info-text {
  text-align: center;
}

#rikishi-fs-recent {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  gap: 10px 30px;
  max-width: 400px;
  margin: auto;
}

@media screen and (max-width: 600px) {
  main {
    margin: 10px auto;
  }

  #dbtest-filters {
    width: 80vw;
    gap: 1vw;
  }

  #dbtest-filters div {
    width: 72vw;
  }

  input {
    margin: 1vw 3vw;
  }

  #dbtest-full-table {
    margin: 2vw;
  }

  table {
    width: 96vw;
  }

  #sorry {
    background-color: rgb(184, 2, 2);
    color: white;
    border: 2px solid goldenrod;
    border-radius: 20px;
    max-width: 90vw;
    margin: 10px auto;
    text-align: center;
    padding: 5px;
  }

  #dbtest-full-table p {
    font-size: 12px;
  }

  .dbtest-one-rikishi {
    height: 40px;
  }

  .dbtest-basho-image, tbody div th .dbtest-rikishi-image {
    width: 40px;
  }

  .dbtest-rikishi-name {
    font-size: 10px;
    width: 85px;
    padding: 13px 0;
  }

  #shikona {
    padding: 18px 0;
  }

  .dbtest-basho-total, .dbtest-rikishi-total, .dbtest-basho-avg, .dbtest-rikishi-avg {
    width: 45px;
    padding: 9px 1px;
  }
}
