/* for the Draft */

#DraftLogin,
#DraftAD {
  background-color: white;
  text-align: center;
  margin: 80px auto;
  padding: 10px;
  border: 3px solid black;
  border-radius: 10px;
}

#DraftLogin {
  width: 250px;
}

#DraftAD {
  width: 240px;
  padding: 0 20px;
  color: black;
}

#DraftAD button {
  margin-bottom: 20px;
  padding: 5px 10px;
  height: auto;
  font-size: 18px;
}

#DraftAD button:hover {
  box-shadow: 0px 0px 10px 3px goldenrod;
}

#DraftTopFlex {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  gap: 20px;
  margin: 20px;
}

#FSTeam {
  border: 1px solid black;
  text-align: center;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
}

#FSTeam h2 {
  margin: 5px;
}

#DraftErrorMessage {
  border: 3px solid red;
  width: 320px;
  margin: 20px auto;
  color: red;
}

#FSFlex {
  display: grid;
  grid-template-columns: 38% 58%;
  gap: 4%;
  margin: auto;
  width: 400px;
  height: 100%;
  max-height: 280px;
}

#FSTeamTable,
#FSRikishi {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-around;
  margin: auto;
  height: 100%;
}

#FSTeamTable {
  width: 120px;
  text-align: right;
}

#FSRikishi {
  width: 220px;
}

#FSRikishi div {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  height: 21.5px;
  /* margin: 16px 0; */
}

#FSRikishi div p, #FSTeamTable p {
  margin: 1px;
}

#FSRikishi button {
  display: inline;
  font-size: 12px;
  height: 12px;
  width: 12px;
  padding: 0px;
  margin: 0 5px;
  border: 0;
  color: black;
  box-shadow: none;

}

#FSRikishi button:hover {
  color: red;
}

#FSTeamBottom button {
  color: white;
  background-color: rgb(184, 2, 2);
  border: 0px;
  padding: 5px 10px;
  height: 35px;
  box-shadow: 0px 0px 10px 3px goldenrod;
  margin: 10px;
}

#warning {
  margin: 5px;
}

.RLarge {
  border: 1px solid black;
  text-align: center;
  width: 500px;
  background-color: white;
  border-radius: 10px;
}

.RLarge hr {
  width: 50px;
}

#RLargeTop {
  width: 400px;
  margin: 120px auto;
}

#AddRikishiButton {
  font-size: 20px;
  margin: 20px auto;
  padding: 5px 20px;
  height: 40px;
}

.content p {
  margin: 10px auto;
}

/* .content {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  justify-content: center;
  align-items: center;
} */

#RLargeImage {
  width: 80px;
  margin: 10px auto;
}

#DraftFilters {
  display: flex;
  flex-flow: row nowrap;
  height: 30px;
  width: 50vw;
  min-width: 550px;
  justify-content: space-between;
  align-items: center;
  margin: 0px auto;
  background-color: white;
  border: 1px solid black;
  padding: 5px 20px;
  border-radius: 5px;
}

#DraftSearch {
  display: flex;
  flex-flow: row nowrap;
  gap: 10px;
}

#DFSelect {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

#orFilter {
  text-align: center;
}

#DFSelect select {
  color: black;
}

#DFCheckboxes {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  width: 600px;
}

#AllRikishiFlex {
  display: flex;
  width: 90%;
  margin: 0 auto;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
}

#Makuuchi,
#Juryo {
  display: block;
  width: 100%;
  margin: 10px 20px;
  background-color: rgb(229, 202, 133);
  border: 1px solid black;
}

#Makuuchi h2,
#Juryo h2 {
  margin: 10px;
}

.RLFlex {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  overflow: scroll;
}

.SmallCard h3,
.SmallCard p {
  margin: 5px auto;
}

.SmallCard h3 {
  width: 120px;
  font-size: 16px;
}

.SmallCard {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  height: 120px;
  width: 120px;
  border: 1px solid black;
  border-radius: 120px;
  background-color: white;
  padding: 5px;
  margin: 10px;
}

.SmallCard:hover {
  box-shadow: 0 3px 6px 6px grey;
}

#FSTeamBottom button:hover,
#AddRikishiButton:hover {
  box-shadow: 0px 3px 10px rgb(184, 2, 2);
}

.smallImage {
  width: 40px;
  margin: 0 auto;
}

.greyed {
  background-color: grey;
  opacity: .7;
}

#headsup {
  width: fit-content;
  padding: 10px;
  margin: 10px auto;
  text-align: center;
  border: 3px solid rgb(184, 2, 2);
  background-color: white;
  border-radius: 5px;
}

#DraftTopMobile {
  width: 95vw;
  display: grid;
  grid-template-columns: 55% 40%;
  gap: 3%;
  margin: 3vw auto 1vw auto;
}

@media screen and (max-width: 600px) {

  .behindBlur::after {
    content: '';
    height: 300vh;
    overflow: hidden;
    background-color: grey;
    opacity: .7;
    position: absolute;
    /* position: -webkit-sticky;
    position: sticky; */
    inset: 0;
    /* animation: fadeIn 1s; */
  }

  #MobileCardClose {
    position: absolute;
    right: 3vw;
    top: 10px;
    background-color: red;
    color: white;
    border: 1px solid black;
    border-radius: 2px;
    font-size: 18px;
  }

  #DraftLogin {
    margin: 10px auto;
  }

  #DraftAD {
    height: 90px;
  }

  #FSTeam {
    padding: 2%;
    width: calc(96% - 2px);
  }

  #FSTeam h2 {
    margin: 5px;
    font-size: calc(100vw / 30);
  }

  #FSFlex {
    display: grid;
    grid-template-columns: 40% 55%;
    gap: 5%;
    margin: auto;
    width: 100%;
    max-height: 180px;
  }

  #FSTeamTable,
  #FSRikishi {
    height: 100%;
    max-height: 200px;
    width: 100%;
  }

  #FSRikishi div {
    margin: 5px 2% 5px 0;
    height: 16px;
  }

  #FSTeamTable p,
  #FSRikishi p {
    font-size: 10px;
    height: 16px;
    margin: 0;
  }

  #warning {
    margin: 5px;
    font-size: 10px;
  }

  .RLargeMobile {
    width: calc(85vw - 2px);
    margin: auto;
    padding: 2vw;
    text-align: center;
    background-color: white;
    border: 1px solid black;
    border-radius: 20px;
    position: fixed;
    top: 20vw;
    left: 5vw;
    z-index: 2;
  }

  .RLarge h2 {
    font-size: 14px;
  }

  #RLargeTop {
    width: 100%;
    margin: auto;
  }

  /* .card-title {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
      } */

  .cardTitleMobile h1 {
    font-size: calc((2 - 1) * 1.2vw + 1.5rem);
    margin: 10px;
  }

  #RLargeImage {
    width: 60px;
    margin: 5px;
  }

  #AddRikishiButton {
    font-size: 12px;
    margin: 5px auto;
    padding: 0 10px;
    height: 30px;
    color: black;
  }

  .content {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    padding-bottom: 0;
  }

  .content p {
    margin: 5px auto;
    font-size: 9px;
  }

  #DraftFilters {
    height: fit-content;
    flex-flow: row wrap;
    width: calc(100% - 12px);
    min-width: 0;
    margin: auto;
    padding: 2vw;
    font-size: 12px;
  }

  #DraftFilters p {
    font-size: 12px;
  }

  #DraftFilters input {
    width: 100%;
    margin: 0;
  }

  #DraftSearch {
    align-items: center;
    flex-flow: row wrap;
    justify-content: center;
    gap: 5px;
    padding-bottom: 1vw;
  }

  #DFSelect {
    flex-flow: row wrap;
    gap: 0px;
    margin-bottom: 2vw;
  }

  #orFilter {
    margin: 1vw 0;
  }

  #clickText {
    background-color: white;
    border: 1px solid black;
    border-radius: 20px;
    padding: 1vw;
    margin: 1vw auto;
    font-size: calc((1.7 - 1) * 1.2vw + .5rem);
    text-align: center;
    width: fit-content;
  }

  #AllRikishiFlex {
    width: 98%;
    display: grid;
    grid-template-columns: 48% 48%;
    gap: 2%;
    margin: auto;
  }

  #Makuuchi,
  #Juryo {
    min-width: 170px;
    margin: 0;
    height: auto;
  }

  #Makuuchi h2,
  #Juryo h2 {
    font-size: calc((2 - 1) * 1.2vw + .7rem);
  }

  #addedRikishiList {
    display: flex;
    flex-flow: column nowrap;
  }

  .SmallCard {
    flex-flow: row nowrap;
    height: 40px;
    width: 43vw;
    border: 1px solid black;
    border-radius: 10px;
    background-color: white;
    padding: 1vw;
    margin: .5vw 1vw;
  }

  .SmallCard h3 {
    width: 80px;
    font-size: 10px;
  }

  .SmallCard h3,
  .SmallCard p {
    margin: 5px;
  }

  #DraftFilters #headsup {
    width: fit-content;
    min-width: 0px;
    font-size: calc((1.7 - 1) * 1.2vw + .4rem);
    padding: 5px;
    margin: 2vw 0 0 0;
  }
}