/* for the Login */

#LoginFlex {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  margin: 80px 10%;
  gap: 50px;

}

#LFFlex {
  margin: 0;
  padding: 0 20px;
  height: fit-content;
  background-color: rgb(250, 240, 215);
  border-radius: 10px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, .7);
}

#LoginForm {
  width: 100%;
  max-width: 450px;
  margin: 0;
}

#LoginLines {
  margin: 20px auto;
  /* background-color: white; */
  padding: 1px 20px;
  border-radius: 20px;
  /* box-shadow: 0px -1px 4px; */
}

.LoginLine {
  display: grid;
  grid-template-columns: 100px 220px;
  gap: 40px;
  align-items: center;
  width: 360px;
  margin: 20px auto;
  font-size: 20px;
  height: 30px;
}

.LoginLine label {
  width: 100px;
}

.LoginLine input {
  font-size: 20px;
  height: 30px;
  width: 212px;
  margin: 0;
}

#LoginSubmit {
  display: block;
  margin: 20px auto;
  height: 35px;
  width: 120px;
  font-size: 18px;
  color: black;
  border: 0px;
  box-shadow: 0px 1px 10px goldenrod;
}

#LoginSubmit:hover,
.LoginLine input:hover {
  border: 2px inset rgb(184, 2, 2);
}

#signup {
  width: 500px;
  text-align: center;
  font-size: 18px;
}

#signup button {
  height: 35px;
  width: 100px;
  font-size: 18px;
}

@media screen and (max-width: 600px) {

  #LoginFlex {
    margin: 10px 0;
    gap: 10px;
  }

  #LFFlex {
    width: 80%;
    padding: 1px 10px;
    height: auto;
    margin: 0 auto;
  }

  #LoginForm {
    padding: 0;
  }

  #LoginLines {
    margin: 10px auto;
    padding: 1px 5px 1px 10px;
  }

  .LoginLine {
    width: 100%;
    grid-template-columns: 70px auto;
    margin: 15px auto;
    font-size: 14px;
    
    gap: 20px;
  }

  .LoginLine label {
    width: 70px;
  }

  .LoginLine input {
    font-size: 16px;
    width: calc(65vw - 70px);
  }

  h2 {
    margin: 10px;
  }

  #LoginSubmit {
    margin: 20px auto 10px auto;
  }

}