/* Homepage */

#HomepageFlex {
    display: flex;
    flex-flow: row nowrap;
    margin: 50px 2%;
    gap: 40px;
    align-items: center;
    justify-content: center;
  }

  #HomeTextFull {
    width: 750px;
  }
  
  #HomepageText {
    text-align: center;
    margin: auto;
    width: 90%;
    max-width: 680px;
    font-size: 22px;
    background-color: white;
    padding: 0px 20px;
    border: 1px solid black;
    border-radius: 10px;
  }
  
  #takanoshoFootball {
    width: 600px;
    height: 768px;
    border: 5px ridge goldenrod;
    margin: 0;
  }

  @media screen and (max-width: 1440px) {
    #HomepageFlex {
      flex-flow: row wrap;
    }
  }

  #ThanksSumoAPI {
    width: 80vw;
    max-width: 500px;
    margin: 1vw auto;
    background-color: white;
    border: 1px solid black;
    padding: 5px 20px;
    border-radius: 10px;
    text-align: center;
  }

@media screen and (max-width: 600px) {
  #hello {
    width: 80vw;
    max-width: 400px;
    margin: 10px auto;
    padding: 5px 10px;
  }

  #hello p {
    font-size: calc((2 - 1) * 1.2vw + .45rem);
  }

  #hello h2 {
    font-size: 16px;
    margin: 5px 0;
  }

  #HomepageFlex {
    margin: 0 3%;
  }

  #HomepageText {
    width: 80vw;
    padding: 0 10px;
    margin: 10px auto;
  }

  #HomepageText p {
    font-size: calc((2 - 1) * 1.2vw + .7rem);
  }

  #ThanksSumoAPI {
    padding: 10px;
  }

  #takanoshoFootball {
    width: 90vw;
    height: calc(90vw * (768 / 600));
    margin: -10px auto 10px auto;
  }
}