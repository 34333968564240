/* Header */

#HeaderTop {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin: 0px 20px 10px 20px;
  }
  
  #HeaderTop a,
  #NavBar a {
    text-decoration: none;
    color: black;
  }
  
  #navLogin,
  #navAccount,
  #NavBar a {
    /* border: 1px solid black; */
    background-color: white;
    padding: 5px 10px;
    box-shadow: 0px 1px 3px;
    border-radius: 30px;
  }


  #navLogin:hover,
  #navAccount:hover,
  #NavBar a:hover {
    border: 2px inset rgb(184, 2, 2);
    margin: -2px;
  }
  
  
  #NavBar {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    gap: 3vw;
    margin: 0 1vw;
  }

  #HeaderTop #SiteTitle {
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(184, 2, 2), rgb(184, 2, 2), rgb(184, 2, 2), rgba(255, 255, 255, 0));
    margin: 10px auto;
    padding: 5px 100px;
    color: white;
    text-align: center;
  
  }
  
  #FullTop {
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(150, 75, 0, 0));
    margin-top: -10px;
  }

  @media screen and (max-width: 700px) {
    #HeaderTop #SiteTitle {
      padding: 5px 40px;
      font-size: calc((2 - 1) * 1.2vw + 1rem);
    }

    #HeaderTop #SiteTitle {
      margin-bottom: 5px;
    }
  
    #HeaderTop {
      margin: 0 10px;
    }
  
    #HeaderTop a,
    #NavBar a {
      font-size: calc((1.5 - 1) * 1.2vw + .7rem);
    }
  
    #NavBar a {
      width: 40%;
      margin: 0px 5px;
    }
  
    #NavBar {
      display: flex;
      flex-flow: row wrap;
      text-align: center;
      gap: 5px;
      margin: 0;
    }
  }